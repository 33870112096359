.servic-cart {
  width: 100%;
  height: auto;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.service-main-cart {
  width: 315px;
  height: 364px;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .servic-cart {
    flex-direction: row;
  }
}

/* @media only screen and (max-width: 700px) {
  .hero-img {
    width: 100%;
}
}
@media only screen and (max-height: 700px) {
  .hero-img {
    height: 10px;
}
} */