/* facility.css */
.facilities-container {
    text-align: center;
    padding: 20px;
}

.facilities-title {
    margin: 0;
}

.facilities-description {
    margin: 10px 0;
}

.facilities-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.facility-card {
    width: calc(43% - 20px);
    text-align: center;
    padding: 10px;
}

.facility-image {
    width: 100%;
    height: 400px; /* Fixed height */
    object-fit: cover; /* Ensures the image covers the area without stretching */
    border-radius: 8px;
    border: 5px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.facility-text {
    margin-top: 10px;
    font-size: 25px;
    font-weight: bold;
    background-color: orange;
}

@media (max-width: 1024px) {
    .facility-card {
        width: calc(50% - 20px);
    }
}


@media (max-width: 768px) {
    .facility-card {
        width: 100%;
    }
}

@media (max-width: 320px) {
    .facility-card {
        width: 90% !important;
        margin: 0 10px; /* Add margin for better spacing */
    }
}
